import React, { useReducer } from "react";

export type AuthFormStep = "IDENTIFY" | "LOGIN" | "SIGN_UP" | "VERIFY" | "COMPLETE";

export type AuthFormReducerState =
  | {
      step: "IDENTIFY";
      email?: string;
    }
  | {
      step: "LOGIN" | "SIGN_UP";
      email: string;
    }
  | {
      step: "VERIFY";
      email: string;
      mobileNumber: string;
      contactNumberId: string;
    }
  | {
      step: "COMPLETE";
    };

export type AuthFormReducerAction =
  | {
      type: "IDENTIFY";
      email: string;
    }
  | {
      type: "TRANSITION";
      to: "LOGIN" | "SIGN_UP";
      email: string;
    }
  | {
      type: "TRANSITION";
      to: "VERIFY";
      mobileNumber: string;
      contactNumberId: string;
    }
  | {
      type: "TRANSITION";
      to: "COMPLETE";
    }
  | {
      type: "RESET";
    };

const initialState: AuthFormReducerState = {
  step: "IDENTIFY",
  email: undefined,
};

const authFormReducer: React.Reducer<AuthFormReducerState, AuthFormReducerAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "RESET":
      return initialState;

    case "IDENTIFY":
      return { step: "IDENTIFY", email: action.email };

    case "TRANSITION":
      if (action.to === "LOGIN" || action.to === "SIGN_UP") {
        return { step: action.to, email: action.email };
      }

      if (action.to === "VERIFY" && state.step === "SIGN_UP") {
        return {
          step: action.to,
          email: state.email,
          mobileNumber: action.mobileNumber,
          contactNumberId: action.contactNumberId,
        };
      }

      if (action.to === "COMPLETE") {
        return { step: action.to };
      }

      throw new Error("Invalid TRANSITION action.");

    default:
      return state;
  }
};

export function useAuthFormReducer() {
  return useReducer(authFormReducer, initialState);
}
