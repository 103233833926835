import { useForm as useRHForm, UseFormProps, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "@/lib/zod";

type FormErrors = { FORM_ERROR?: null };

type UseFormInput<S extends z.ZodTypeAny> = UseFormProps<z.infer<S>> & {
  schema: S;
};

export function useForm<S extends z.ZodTypeAny>({ schema, ...rest }: UseFormInput<S>) {
  return useRHForm<z.infer<S> & FormErrors>({
    resolver: zodResolver(schema),
    ...rest,
  });
}

export type InferFormValues<S extends z.ZodTypeAny> = z.infer<S>;

export type FormSubmitHandler<S extends z.ZodTypeAny, T = InferFormValues<S> & FormErrors> = (
  values: T,
  form: UseFormReturn<T>
) => Promise<void>;
