import { Link } from "@releaseit/ui";

export function AuthTermsAndConditions() {
  return (
    <p className="text-center text-sm">
      By continuing, you agree to the{" "}
      <Link variant="neutral" href="/page/terms-of-use" target="_blank">
        Terms of Use
      </Link>{" "}
      and the collection, use and disclosure of your personal information in accordance with our{" "}
      <Link variant="neutral" href="/page/privacy-policy" target="_blank">
        Privacy Policy
      </Link>{" "}
      and consent to receiving marketing communications from Releaseit.
    </p>
  );
}
