import { getSession, signIn } from "next-auth/react";

import { useLoginWithPasswordMutation } from "@/generated/graphql";
import { queryClient } from "@/lib/react-query";
import { analytics } from "@/utils/analytics";
import { gqlClient } from "@/utils/graphql-client";

type LoginWithPasswordInput = {
  email: string;
  password: string;
  staySignedIn: boolean;
};

export async function loginWithPassword(credentials: LoginWithPasswordInput) {
  // Sign-in for both v1 and v2
  const [, res] = await Promise.all([
    useLoginWithPasswordMutation.fetcher(credentials)(), // TODO[v1]: Remove this once v1 is deprecated
    signIn<"credentials">("credentials", { ...credentials, redirect: false }),
  ]);

  if (!res || !res.ok) {
    throw new Error(res?.error);
  }

  const session = await getSession();
  gqlClient.headers.set("Authorization", `Bearer ${session!.accessToken}`);

  analytics.identifyUser(session!.user);

  // Invalidate all queries to force refetch with the access token
  queryClient.invalidateQueries();
}
