import { useMutation } from "react-query";

import { Alert, Button } from "@releaseit/ui";

export type CompleteStepProps = {
  onComplete: () => Promise<unknown>;
};

export function CompleteStep({ onComplete }: CompleteStepProps) {
  const { mutate: complete, isLoading } = useMutation(onComplete);

  return (
    <div className="flex flex-col space-y-6">
      <h1 className="text-center text-xl font-medium">Sign-up complete</h1>
      <Alert tone="positive">You have successfully created your account.</Alert>
      <Button type="submit" onClick={() => complete()} isLoading={isLoading}>
        Continue
      </Button>
    </div>
  );
}
