import { useState } from "react";

import { Button, TextInput } from "@releaseit/ui";

import { useCheckAccountExistsQuery } from "@/generated/graphql";
import { InferFormValues, useForm } from "@/hooks/use-form";
import { z } from "@/lib/zod";

import { AuthTermsAndConditions } from "./AuthTermsAndConditions";

const schema = z.object({
  email: z.string().email(),
});

export type IdentifyStepProps = {
  onIdentify: ({ email, exists }: { email: string; exists: boolean }) => void;
};

export function IdentifyStep({ onIdentify }: IdentifyStepProps) {
  const { register, handleSubmit, formState, ...form } = useForm({ schema });

  function onSubmit(values: InferFormValues<typeof schema>) {
    setEmail(values.email);
  }

  const [email, setEmail] = useState("");
  const checkQuery = useCheckAccountExistsQuery(
    { email },
    {
      enabled: Boolean(email),
      refetchOnWindowFocus: false,
      onSuccess(data) {
        onIdentify({ email, exists: data.CheckAccountExists.exists });
      },
      onError(error) {
        form.setError("email", { message: error.message });
      },
    }
  );

  return (
    <form className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-center text-xl font-medium">Login or Sign Up</h1>

      <fieldset className="flex flex-col space-y-4" disabled={checkQuery.isLoading}>
        <TextInput
          {...register("email")}
          label="Email"
          type="email"
          isRequired
          minLength={3}
          error={formState.errors.email?.message}
        />

        <Button type="submit" isLoading={checkQuery.isLoading}>
          Continue
        </Button>
      </fieldset>

      <AuthTermsAndConditions />
    </form>
  );
}
