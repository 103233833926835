import { useMutation } from "react-query";

import { Button, Checkbox, PasswordInput } from "@releaseit/ui";

import { InferFormValues, useForm } from "@/hooks/use-form";
import { z } from "@/lib/zod";

import { loginWithPassword } from "../../api/login";
import { AuthTermsAndConditions } from "./AuthTermsAndConditions";

const schema = z.object({
  password: z
    .string()
    .min(8, "Must be at least 8 characters.")
    .max(32, "Must be at most 32 characters.")
    .regex(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{0,}$/,
      "Must contain one uppercase, one lowercase and one number."
    ),
  staySignedIn: z.boolean(),
});

export type LoginStepProps = {
  email: string;
  onLogin: () => Promise<unknown> | void;
};

export function LoginStep({ email, onLogin }: LoginStepProps) {
  const { register, handleSubmit, formState, ...form } = useForm({ schema });

  function onSubmit(values: InferFormValues<typeof schema>) {
    loginMutation.mutate({ email, ...values });
  }

  const loginMutation = useMutation({
    mutationFn: loginWithPassword,
    onSuccess: onLogin,
    onError(error: Error) {
      form.setError("password", {
        message:
          error?.message === "CredentialsSignin"
            ? "Incorrect email or password"
            : "Something went wrong. Please try again.",
      });
    },
  });

  return (
    <form className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-center text-xl font-medium">Confirm your password</h1>

      <fieldset className="flex flex-col space-y-4" disabled={loginMutation.isLoading}>
        <input name="email" type="email" defaultValue={email} readOnly hidden />

        <PasswordInput
          {...register("password")}
          label="Password"
          autoComplete="existing-password"
          isRequired
          minLength={8}
          maxLength={32}
          error={formState.errors.password?.message}
        />

        <Button type="submit" isLoading={loginMutation.isLoading}>
          Login
        </Button>

        <Checkbox {...register("staySignedIn")} label="Stay signed in?" isDefaultChecked />
      </fieldset>

      <AuthTermsAndConditions />
    </form>
  );
}
