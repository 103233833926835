import { useRegisterMutation } from "@/generated/graphql";
import { analytics } from "@/utils/analytics";
import { InputError } from "@/types/errors";

import { loginWithPassword } from "./login";

type RegisterWithPasswordInput = {
  email: string;
  password: string;
  lastName: string;
  firstName: string;
  mobileNumber: string;
  referrerId?: string;
};

export async function registerWithPassword(input: RegisterWithPasswordInput) {
  const { Register } = await useRegisterMutation.fetcher({
    ...input,
    mobileNumber: { countryCode: "61", mobileNumber: input.mobileNumber },
  })();

  if (Register.__typename === "InputValidationErrors") {
    throw InputError.fromGQLResponse(Register);
  }

  // Log the user in after registration
  await loginWithPassword({
    email: input.email,
    password: input.password,
    staySignedIn: true,
  });

  analytics.providers.ga.event("registered", { event_category: "user" });
  analytics.providers.fbp.event("user.registered", {});

  return Register;
}
