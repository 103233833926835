import { Button, TextInput } from "@releaseit/ui";

import { useVerifyPhoneNumberMutation } from "@/generated/graphql";
import { InferFormValues, useForm } from "@/hooks/use-form";
import { z } from "@/lib/zod";
import { isGQLError } from "@/types/errors";

const CODE_LENGTH = 4;

export const schema = z.object({
  code: z
    .string()
    .regex(/[0-9]+$/, "Must be a 4-digit code.")
    .length(CODE_LENGTH, "Must be a 4-digit code."),
});

export type VerifyStepProps = {
  mobileNumber: string;
  contactNumberId: string;
  onVerify: () => Promise<void> | void;
};

export function VerifyStep({ mobileNumber, contactNumberId, onVerify }: VerifyStepProps) {
  const { register, handleSubmit, formState, ...form } = useForm({ schema });
  function onSubmit(values: InferFormValues<typeof schema>) {
    verifyQuery.mutate({ contactNumberId, verificationCode: values.code });
  }

  const verifyQuery = useVerifyPhoneNumberMutation({
    async onSuccess(data) {
      if (isGQLError(data.VerifyPhoneNumber)) {
        form.setError("code", { message: data.VerifyPhoneNumber.message });
        return;
      }

      await onVerify();
    },
  });

  return (
    <form className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-center text-xl font-medium">Verify mobile number</h1>

      <p className="text-center">
        Enter the 4-digit code sent to your mobile number <strong>{mobileNumber}</strong>
      </p>

      <fieldset className="flex flex-col space-y-4" disabled={verifyQuery.isLoading}>
        <TextInput
          {...register("code")}
          label="Verification code"
          isRequired
          error={formState.errors.code?.message ?? verifyQuery.error?.message}
        />

        <Button type="submit" isLoading={verifyQuery.isLoading}>
          Verify
        </Button>
      </fieldset>
    </form>
  );
}
