import { type GqlError } from "@/generated/graphql";

export function isGQLError(error: any): error is GqlError {
  const hasCodeAndMessage = "code" in error && "message" in error;
  const endsWithError = error.__typename?.endsWith("Error");

  return hasCodeAndMessage && endsWithError;
}

export * from "./InputError";
export * from "./SanitizedGQLError";
