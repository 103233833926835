import { type UseFormReturn } from "react-hook-form";

import { type InputValidationError, type InputValidationErrors } from "@/generated/graphql";

export class InputError extends Error {
  fields: { [key: string]: string };

  constructor(fields: { [key: string]: string }) {
    super("Invalid input");

    this.name = "InputError";
    this.fields = fields;
  }

  public assignToForm(form: Pick<UseFormReturn<any>, "setError">) {
    Object.entries(this.fields).forEach(([field, message]) => {
      form.setError(field, { message });
    });
  }

  /** Static methods */

  public static fromGQLResponse(error: {
    __typename: InputValidationErrors["__typename"];
    errors: Pick<InputValidationError, "field" | "message">[];
  }): InputError {
    const fieldEntries = error.errors.map((fieldError) => [fieldError.field, fieldError.message]);
    return new this(Object.fromEntries(fieldEntries));
  }
}
